<template>
  <el-form
    v-if="lesson"
    ref="form"
    :model="lesson"
    label-width="160px"
    @submit.prevent.native="submit"
  >
    <el-form-item
      required
      label="Order"
      prop="order"
      :rules="[commonRules.input]"
    >
      <el-input-number v-model="lesson.order" />
    </el-form-item>
    <el-form-item
      required
      label="Title (EN)"
      prop="title.en"
      :rules="[commonRules.input]"
    >
      <el-input v-model="lesson.title.en" />
    </el-form-item>
    <el-form-item
      required
      label="Title (TW)"
      prop="title.tw"
      :rules="[commonRules.input]"
    >
      <el-input v-model="lesson.title.tw" />
    </el-form-item>
    <el-form-item
      required
      label="Title (CN)"
      prop="title.cn"
      :rules="[commonRules.input]"
    >
      <el-input v-model="lesson.title.cn" />
    </el-form-item>
    <el-form-item
      required
      label="Instructor Name"
      prop="instructor_name"
      :rules="[commonRules.input]"
    >
      <el-input v-model="lesson.instructor_name" />
    </el-form-item>
    <el-form-item
      required
      label="Description (EN)"
      prop="title.en"
      :rules="[commonRules.input]"
    >
      <el-input v-model="lesson.description.en" />
    </el-form-item>
    <el-form-item
      required
      label="Description (TW)"
      prop="title.tw"
      :rules="[commonRules.input]"
    >
      <el-input v-model="lesson.description.tw" />
    </el-form-item>
    <el-form-item
      required
      label="Description (CN)"
      prop="title.cn"
      :rules="[commonRules.input]"
    >
      <el-input v-model="lesson.description.cn" />
    </el-form-item>
    <el-form-item required prop="default_cover">
      <template slot="label">
        Default Cover
        <el-tooltip
          content="This picture will be displayed when the Preview Video Url is empty!"
          placement="top"
          popper-class="tooltipColor"
        >
          <span class="action-icon">
            <i class="fas fa-question-circle" />
          </span>
        </el-tooltip>
      </template>
      <div>
        <el-upload
          :action="imageAction"
          :headers="token"
          :data="{ path: 'lesson' }"
          list-type="picture"
          name="image"
          :file-list="fileList.default_cover"
          :on-success="
            (response, file, fileList) =>
              handleUploadSuccess(response, file, fileList, 'default_cover')
          "
          :on-remove="
            (file, fileList) => removeUploadedPhoto(file, fileList, 'default_cover')
          "
        >
          <el-button size="small" type="primary">
            {{ $t("userProfile.basic.upload") }}
          </el-button>
        </el-upload>
      </div>
    </el-form-item>
    <el-form-item required label="Preview Cover" prop="preview_cover">
      <div>
        <el-upload
          :action="imageAction"
          :headers="token"
          :data="{ path: 'lesson' }"
          list-type="picture"
          name="image"
          :file-list="fileList.preview_cover"
          :on-success="
            (response, file, fileList) =>
              handleUploadSuccess(response, file, fileList, 'preview_cover')
          "
          :on-remove="
            (file, fileList) => removeUploadedPhoto(file, fileList, 'preview_cover')
          "
        >
          <el-button size="small" type="primary">
            {{ $t("userProfile.basic.upload") }}
          </el-button>
        </el-upload>
      </div>
    </el-form-item>
    <el-form-item label="Preview Video Url" prop="preview_video_url">
      <el-input v-model="lesson.preview_video_url" placeholder="Youtube Id">
        <template slot="prepend">https://www.youtube.com/embed/</template>
      </el-input>
    </el-form-item>
    <el-form-item required label="Cover" prop="cover">
      <div>
        <el-upload
          :action="imageAction"
          :headers="token"
          :data="{ path: 'lesson' }"
          list-type="picture"
          name="image"
          :file-list="fileList.cover"
          :on-success="
            (response, file, fileList) =>
              handleUploadSuccess(response, file, fileList, 'cover')
          "
          :on-remove="
            (file, fileList) => removeUploadedPhoto(file, fileList, 'cover')
          "
        >
          <el-button size="small" type="primary">
            {{ $t("userProfile.basic.upload") }}
          </el-button>
        </el-upload>
      </div>
    </el-form-item>
    <el-form-item label="Video Url" prop="video_url">
      <el-input v-model="lesson.video_url" placeholder="Youtube Id">
        <template slot="prepend">https://www.youtube.com/embed/</template>
      </el-input>
    </el-form-item>
    <el-form-item
      required
      label="Label"
      prop="label_id"
      :rules="[commonRules.input]"
    >
      <el-select v-model="lesson.label_id">
        <el-option
          :key="key"
          v-for="key in Object.keys(labels)"
          :value="key"
          :label="labels[key][lang]"
        />
      </el-select>
    </el-form-item>
    <el-form-item
      required
      label="Premiere Date"
      prop="premiere_date"
      :rules="[commonRules.input]"
    >
      <el-date-picker
        value-format="yyyy-MM-dd HH:mm:ss"
        class="datePicker"
        type="datetime"
        v-model="lesson.premiere_date"
      />
    </el-form-item>
    <el-form-item class="d-flex justify-content-center">
      <router-link :to="{ name: 'Lessons' }" class="mr-4">
        <el-button native-type="submit">
          Back
        </el-button>
      </router-link>
      <el-button type="primary" native-type="submit">
        {{ submitText }}
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import JwtService from "@/common/jwt.service";
import formMixin from "@/mixins/form";

export default {
  props: {
    defaultLesson: {
      type: Object,
      default: null
    },
    submitText: {
      type: String,
      default: ""
    }
  },
  mixins: [formMixin],
  computed: {
    lang() {
      return this.$store.getters["user/langShortValue"];
    },
    imageAction() {
      return process.env.VUE_APP_BACKEND_URL + "image";
    },
    token() {
      return { Authorization: `Bearer ${JwtService.getToken()}` };
    }
  },
  data() {
    return {
      fileList: {
        default_cover: [],
        preview_cover: [],
        cover: []
      },
      labels: {
        reading: { en: "Reading", cn: "阅读", tw: "閱讀" },
        listening: { en: "Listening", cn: "听力", tw: "聽力" },
        speaking: { en: "Speaking", cn: "口说", tw: "口說" },
        writing: { en: "Writing", cn: "写作", tw: "寫作" }
      },
      lesson: {
        order: 0,
        title: {
          en: "",
          cn: "",
          tw: ""
        },
        instructor_name: "",
        description: {
          en: "",
          cn: "",
          tw: ""
        },
        default_cover: "",
        video_url: "",
        preview_video_url: "",
        cover: "",
        preview_cover: "",
        label_id: "",
        label: { en: "", cn: "", tw: "" },
        premiere_date: new Date()
      }
    };
  },
  created() {
    if (this.defaultLesson) {
      this.lesson = {
        ...this.lesson,
        ...this.defaultLesson,
        label_id: this.defaultLesson.label.en.toLowerCase(),
        premiere_date: new Date(this.defaultLesson.premiere_date),
        video_url: this.defaultLesson.video_url.replace(
          "https://www.youtube.com/embed/",
          ""
        ),
        preview_video_url: this.defaultLesson.preview_video_url.replace(
          "https://www.youtube.com/embed/",
          ""
        )
      };
      const FileFactory = key => ({
        name: "",
        url: this.lesson[key],
        response: { image: { url: this.lesson[key] } }
      });
      this.fileList = {
        cover: [FileFactory("cover")],
        preview_cover: [FileFactory("preview_cover")],
        default_cover: [FileFactory("default_cover")]
      };
    }
  },
  methods: {
    handleUploadSuccess(response, file, fileList, key) {
      this.lesson[key] = response.image.url;
      this.fileList[key] = fileList;
      if (this.fileList[key].length >= 2) {
        this.removeUploadedPhoto(file, fileList, key);
      }
    },
    async removeUploadedPhoto(file, fileList, key) {
      if (this.fileList[key][0].response.image.url) {
        await this.$store.dispatch(
          "registerDetails/deleteUploadFileByUrl",
          this.fileList[key][0].response.image.url
        );
      }
      if (fileList.length >= 2) {
        this.fileList = {
          ...this.fileList,
          [key]: [fileList[1]]
        };
      } else {
        this.fileList = {
          ...this.fileList,
          [key]: []
        };
        this.lesson[key] = "";
      }
    },
    async submit() {
      const isValid = await this.validateForm(this.$refs.form);
      if (isValid) {
        this.$emit("submit", {
          ...this.lesson,
          label: this.labels[this.lesson.label_id],
          video_url: `https://www.youtube.com/embed/${this.lesson.video_url}`,
          preview_video_url: `https://www.youtube.com/embed/${this.lesson.preview_video_url}`
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  padding-top: 40px;
}
</style>
